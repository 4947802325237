/* ---------------------------------------------------

    General

----------------------------------------------------- */

.tableau-container  {
  /* position: relative; */
}

.tableau_hide_div  {
  background-color: white;
  width: 100%;
  height: 27px;
  position: absolute;
}

.blured {
   filter: blur(3px)
}

/* ---------------------------------------------------


    Login and create Account

----------------------------------------------------- */

.social-auth-div {

}

.create-account .seperator {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.create-account .social-auth {
  text-align: center;
}

.login .seperator {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.login .social-auth {
  text-align: center;
}

/* ---------------------------------------------------

    Login and create Account

----------------------------------------------------- */



/* ---------------------------------------------------
    Header Mobile


----------------------------------------------------- */
.shim-red-line {
  height: 2px;
}

.shim-red {
  position: relative;
  overflow: hidden;
  background-color: var(--purplepink);
}
.shim-red::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0,
    rgba(233, 233, 233, 0.9) 50%,
    rgba(233, 233, 233, 0.8) 100%
  );
  animation: shimmer 3s ease-out infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}

.header-search .main12 {
}

.header-search .content-div {
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 7px;
}
.header-search .clicked {
  width: 75%;
}


.header-search .remove-div {
  padding-bottom: 3px;
  bottom: 0;
  right:5%;
  position: absolute
}

.header-search .cancel-div {
  padding-bottom: 3px;
  bottom: 0;
  right: 5%;
  position: absolute
}

.header-search .input-container {
  border: 1px solid #dce4ec;
  border-radius: 10px;
  display:flex;
}

.header-search .placeholder-div {
  padding-bottom: 3px;
  bottom: 0;
  left:40%;
  position: absolute
}



.header-search .content-div input:focus{
    outline: none;
    caret-color: black;
}


.header-search .search-icon-div {
  padding-top: 2px;
  padding-left: 30%;
}

.header-search .search-icon-div-search {
  padding-top: 2px;
  padding-left: 10px;
}

.header-search .search-icon {
  width: 12px;
  height:12px;
  display: inline;
  fill: var(--midgrey);
}

.header-search .search-input-div {
  width: 100%;
  font-size: 16px;
}

.header-search .search-input {
  font-size: 16px;
  width: 100%;
  height:30px;
  display:inline;
}

.header-search .placeholder-div {
  padding-bottom: 3px;
  bottom: 0;
  position: absolute
}

.header-search .placeholder-div-search {
  padding-bottom: 3px;
  bottom: 0;
  left:30px;;
  position: absolute
}


.header-search .remove-icon {
  width: 12px;
  height:12px;
  display: inline;
  fill: var(--midgrey);
  right: 0;
}


.layout-mobile {
}
.main-mobile {
}

.header-mobile{
  height: 44px;
  border-bottom: 1px solid #dce4ec;
  background-color: var(--white);
}

.footer-mobile{
  height: 44px;
  border-top: 1px solid #dce4ec;
  background-color: var(--white);
}

.footer-mobile .nav-icon{
  height: 30px;
  display: block;
  margin: auto;
  margin-top: 5px;
  text-decoration-color:blue;
}

.insights-view .nav-icon{
  height: 30px;
  display: block;
  margin: auto;
  margin-top: 5px;
  text-decoration-color:blue;
}

.header-mobile .logo{
  height:  35px;
}

.header-mobile .logo-div{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  display: flex;
  padding-top: 5px;
}

.header-mobile .back-div{
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-top: 10px;
  padding-left: 16px;
}

.header-mobile .ident-div{
  padding-top: 7px;
  text-align: center;
}

.header-mobile .ident_text{
  font-family: Montserrat;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
  font-weight: 600;
  font-size: 18px;
}

/* ---------------------------------------------------
    Grow View




----------------------------------------------------- */

.header-headline {
  height: 60px;
  padding-left: 16px;
  padding-right: 16px;
}

/* ---------------------------------------------------
    Grow View


----------------------------------------------------- */
.grow-view {
  min-height: 95vh
}

.grow-view .cta{
  margin-left: 3px;
}

.grow-view .todo-headline{
  padding-bottom: 16px;
}

.grow-view .icon-div{
  padding-left: 5px;
  text-align: center ;
  align-content: center;
  display: inline;
}

/* ---------------------------------------------------
    Story-Saver


----------------------------------------------------- */

:root {
	--size-avatar: 56px;
	--size-border: 2px;
	--color-background: white;
}

.story-saver .avatar {
	align-items: center;
	background-image: linear-gradient(
		45deg,
		#ffa95f 5%,
		#f99c4a 15%,
		#f47838 30%,
		#e75157 45%,
		#d92d7a 70%,
		#cc2a92 80%,
		#c32e92 95%
	);
	box-sizing: border-box;
	display: flex;
	height: var(--size-avatar);
	justify-content: center;
	overflow: hidden;
	padding: var(--size-border);
	width: var(--size-avatar);
		border-radius: 50%;
}

.story-saver .avatar-img {
		border-width: calc(0.5 * var(--size-border));
		height: auto;
		margin: 0;
		width: 100%;
		transform: scale(1);
}

.story-saver .has-story img {
  border-radius: 9999px;
	border: var(--size-border) solid var(--color-background, white);
}

.story-saver-watch  {
  top:0;
  width: 100%;
}

.story-saver-watch .next-story  {
  background-color: var(--midgrey);
  border-radius: 50%;
  top:50%;
  right:10px;
  position: absolute;
}

.story-saver-watch .previous-story  {
  background-color: var(--midgrey);
  border-radius: 50%;
  top:50%;
  left:10px;
  position: absolute;
}

.story-saver-watch .download-button-div  {
  padding-left: 10px;
  padding-right: 10px;
  bottom:20px;
  position: absolute;
  width: 100%;
  color: white;
}

.story-saver .profile-search-avatar-div  {
  align-items: center;
  background-image: linear-gradient(
    45deg,
    #ffa95f 5%,
    #f99c4a 15%,
    #f47838 30%,
    #e75157 45%,
    #d92d7a 70%,
    #cc2a92 80%,
    #c32e92 95%
  );
  box-sizing: border-box;
  display: flex;
  height: var(--size-avatar);
  justify-content: center;
  overflow: hidden;
  padding: var(--size-border);
  width: var(--size-avatar);
  border-radius: 50%;
  margin-right: 3px;
}

.story-saver .story-watch-placeholder  {
  width: 100%;
  height: 600px;
  background-color: grey;
}

.story-saver .banner-button  {
  position: absolute;
  bottom: 15px;
  left: 20%;
}


/* ---------------------------------------------------
    Story-Saver


----------------------------------------------------- */
/* ---------------------------------------------------
    Setup



----------------------------------------------------- */

.setup-start {
  padding-left: 30px;
  padding-right: 30px;
}

.setup-start .logo-container {
  align-items: center;
  text-align: center;
  padding-top: 30%;
  height: 40%;
  padding-bottom: 10px;
}

.setup-start .logo-influxio {
  height: 60px;
  display: inline-block;
}

.setup-start .button-container {
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.setup-start .login-button-container {
  padding-bottom: 13px;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setup-set-account {
  padding-top: 16px;
  padding-left: 30px;
  padding-right: 30px;
}

.setup-set-account .top-container{
  margin-top: 20px;
  height: 250px;
}

.setup-set-account .private-text-container{
  text-align: center;
  height: 100px;
  padding-bottom: 16px;
}

.setup-set-account .loading-container{
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setup-set-account .avatar-container{
  height: 150px;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setup-set-account .form-container{
}

.setup-set-account .username-input{
  background-color:#FAFAFA;
  margin-bottom: 30px;
  text-align: center;
}

.setup-set-account .proceed-button{
  width:100%;
}

.setup-search .avatar-item-container{
  padding-bottom: 5px;
}

.setup-search .details{
  padding-left: 30px;
}

.explore-item .main-container{
  display: flex;
}

.explore-item .avatar{
  min-width: 50px;
  min-height: 40px;
}

.explore-item .detail-container{
}

.explore-item .detail-top{
}

.explore-item .detail-bottom{
}

/* ---------------------------------------------------
    Setup


----------------------------------------------------- */



/* ---------------------------------------------------
    Analytics


----------------------------------------------------- */

.insights-view {
  min-height: 90vh;
}

.insights-view .header {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
}

.insights-view .fan-view .stats {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: lightgray;
  padding-left: 6px;
  padding-right: 6px;
  text-align: center;
}

.insights-view .fan-view .fan-list {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 6px;
  padding-right: 6px;
}

.grow-item {
  padding-bottom: 13px;
}

.grow-item .top-container{
  display: flex;
  padding-bottom: 5px;
}

.grow-item .avatar-container{
  padding-right: 16px;
}
.grow-item .action-container{
  width: 65%;
  position: relative;
}

.grow-item .button-div {
  width: 50px;
  right: 0px;
  top:0px;
  position: absolute;
}

.grow-item .name-container {
  padding-top: 30px;
}

.grow-item .button-container {
  padding-bottom: 5px;
  height: 80px;
}

.grow-item .later-container {
  padding-bottom: 5px;
}

.grow-item .grow-button {
  width: 100%;
}

.grow-item .bio-container {
  padding-bottom: 5px;
  max-width: 80vW;
}

.grow-item .details{
  padding-left: 16px;
  text-align: center;
}

.grow-item .follower{
}


.insights-view .fan-view .fan-list{
  padding-left: 13px;
}

.insights-view .fan-view .fan-list .avatar{
  min-width: 100px;
  display:inline;
}

.insights-view .fan-view .fan-list .details{
}

.insights-view .fan-view .bottom-content {
}

.insights-view .fan-view .avatar-container {
  padding-top: 30%;
  position: relative;
}

.insights-view .fan-view .avatar-1 {
  display: inline;
}
.insights-view .fan-view .avatar-2 {
  display: inline;
  margin-left: -20px;
}
.insights-view .fan-view .avatar-3 {
  display: inline;
  margin-left: -20px;
}

.insights-view .follower-interesting-view .stats {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: lightgray;
  padding-left: 6px;
  padding-right: 6px;
  text-align: center;
}

.insights-view .follower-interesting-view .fan-list {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 6px;
  padding-right: 6px;
}

.insights-view .follower-interesting-view .fan-list .container{
}

.insights-view .follower-interesting-view .fan-list .avatar{
  display:inline;
}

.insights-view .follower-interesting-view .fan-list .details{
  padding-left: 30px;
}

.insights-view .follower-interesting-view .bottom-content {
}

.insights-view .follower-interesting-view .avatar-container {
  padding-top: 20%;
  position: relative;
}

.insights-view .follower-interesting-view .avatar-1 {
  display: inline;
}
.insights-view .follower-interesting-view .avatar-2 {
  display: inline;
  margin-left: -20px;
}
.insights-view .follower-interesting-view .avatar-3 {
  display: inline;
  margin-left: -20px;
}

.insights-view .select-container {
  height: 70px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #dce4ec;
}

.insights-view .select-container .select-icon {
  height: 30px;
  display: block;
  margin: auto;
  margin-top: 5px;
}


.explore-history {
  border-top: 1px solid #dce4ec;
  padding-top: 5px;
}

.explore-influencer {
  padding-left: 16px;
}

/* ---------------------------------------------------
    Analytics
/*  */

.inspire-view .todo-div{
  width: 64px;
  height: 64px;
}

.inspire-view .tod-box{
  width: 350px;
}

.inspire-view .media-grid-small{
  height: 50%;
  background-color: grey;
}

.inspire-view .media-grid-big{
  height: 100%;
  background-color: grey;
}

.inspire-view .placeholder {
  height : 510px;
}

.inspire-view .play-icon {
  width: 50px;
  height : 50px;
  fill: white;
}

.inspire-view .play-icon-div {
  position : absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.inspire-view .media-type-icon-div-small {
  position : absolute;
  top: 5%;
  right: 5%;
}

.inspire-view .media-type-icon-div-big {
  position : absolute;
  bottom: 5%;
  left: 5%;
}

.inspire-view .media-type-icon {
  fill: white;
}

.inspire-view .feed-info{

}

.inspire-view .info-item-text-div{
  text-align: center;
}

.inspire-view .info-item-icon{
  width: 26px;
  height: 26px;
}

.analytics-profile-detail .todo-div{
  width: 64px;
  height: 64px;
}

.analytics-profile-detail .tod-box{
  width: 350px;
}

.analytics-profile-detail .media-grid-small{
  height: 50%;
  background-color: grey;
}

.analytics-profile-detail .media-grid-big{
  height: 100%;
  background-color: grey;
}

.analytics-profile-detail .placeholder {
  height : 510px;
}

.analytics-profile-detail .play-icon {
  width: 50px;
  height : 50px;
  fill: white;
}

.analytics-profile-detail .play-icon-div {
  position : absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.analytics-profile-detail .media-type-icon-div-small {
  position : absolute;
  top: 5%;
  right: 5%;
  background-color: var(--anthracite);
  border-radius: 5px;
  padding: 2px;
  opacity: .75;
}

.analytics-profile-detail .media-type-icon-div-big {
  position : absolute;
  bottom: 5%;
  left: 5%;
}

.analytics-profile-detail .media-type-icon {
  fill: white;
}

.analytics-profile-detail .feed-info{

}

.analytics-profile-detail .image-container{
  width:100%
}

.analytics-profile-detail .engagement-container{
  text-align: center;
  position : absolute;
  bottom: 10%;
  left: 25%;
  display: flex;
  color: white;
  background-color: var(--anthracite);
  border-radius: 5px;
  padding: 3px;
  opacity: .75;
}

.analytics-profile-detail .like-container{
  padding-right: 5px;

}

.analytics-profile-detail .comment-container{
}

.analytics-profile-detail .engagement-icon{
  fill: white;
}

.analytics-profile-detail .info-item-text-div{
  text-align: center;
}

.analytics-profile-detail .info-item-icon{
  width: 26px;
  height: 26px;
}

.analytics-profile-detail .image-row{
  min-height: 112px;
  background-color: gray;
}

.inspire-influencer-feed-media  .header{
  height: 60px;
  padding-left: 16px;
  padding-right: 16px;
}

.inspire-influencer-feed-media  .header-avatar{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.inspire-influencer-feed-media  .header-username{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 13%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.inspire-influencer-feed-media  .main{
}

.inspire-influencer-feed-media  .footer{
  height: 170px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 5px;
}



.button-header-ll{
  background-color: var(--purplepink);
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  height:32px;
}

.profile-ii-feed .button-media-ll{
  background-color: var(--purplepink);
  color: white;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  height:20px;
}

.profile-ii-feed .ll-button-div {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 30%;
  text-align: center;
  color:white;
}

.profile-dashboard {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 10px;
  padding-right: 10px;
  /* background-color: #FAFAFA; */
  background-color: lightgray;
}


.profile-dashboard .card-container{
}

.profile-dashboard .card{
  background-color: white;
  height:100%;
}

.influencer-feed {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 23px;
  border-bottom: 1px solid #dce4ec;
}

.influencer-feed .header-container {
  padding-top: 16px;
  padding-bottom: 24px;
}

.influencer-feed .header-container .button-container{
}

.influencer-feed .avatar-div {
  width :100px;
  height:100px;
}

.influencer-feed .bio-container {
  padding-bottom: 21px;

}

.influencer-feed .metadata-container {
  border-top: 1px solid #dce4ec;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #dce4ec;
}

.influencer-feed .metadata-container .element{
  text-align: center;
}

.influencer-feed .interest-container {
  border-bottom: 1px solid #dce4ec;
  padding-bottom: 12px;
  padding-top: 12px;
}

.influencer-feed .interest-container .element{
  border-radius: 50%;
  margin-right: 10px;
  padding: 12px 0;
}

.influencer-feed .interest-container .hashtag-list{
  text-align: center;
}

.influencer-feed .interest-container .hashtag{
  font-family: Montserrat;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 600;
  color: #00376b;
  fontsize: 12px;
}



.influencer-feed .interest-container .myicon{
  display: inline-block;
}


.influencer-feed .media-container {
  border-bottom: 1px solid #dce4ec;
  padding-bottom: 12px;
  padding-top: 12px;
}

.influencer-feed .media-container .type-icon-div{
  position : absolute;
  top: 5%;
  right: 5%;
}

.analytics-profile-interest {
  padding-top: 7px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 23px;
  border-bottom: 1px solid #dce4ec;
}

.analytics-profile-detail {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 23px;
  border-bottom: 1px solid #dce4ec;
}

.analytics-profile-detail .header-container {
  padding-top: 5px;
  padding-bottom: 5px;
}

.analytics-profile-detail .header-container .button-container{
}

.analytics-profile-detail .avatar-div {
  width :100px;
  height:100px;
}

.analytics-profile-detail .bio-container {
  min-height: 75px;
  padding-bottom: 21px;
}

.analytics-profile-detail .metadata-container {
  border-top: 1px solid #dce4ec;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #dce4ec;
}

.analytics-profile-detail .metadata-container .element{
  text-align: center;
}

.analytics-profile-detail .interest-container {
  border-bottom: 1px solid #dce4ec;
  padding-bottom: 12px;
  padding-top: 12px;
}

.analytics-profile-detail .interest-container .element{
  border-radius: 50%;
  margin-right: 10px;
  padding: 12px 0;
}

.analytics-profile-detail .interest-container .hashtag-list{
  text-align: center;
}

.analytics-profile-detail .interest-container .hashtag{
  font-family: Montserrat;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 600;
  color: #00376b;
  fontsize: 12px;
}



.analytics-profile-detail .interest-container .myicon{
  display: inline-block;
}


.analytics-profile-detail .media-container {
  border-bottom: 1px solid #dce4ec;
  padding-bottom: 12px;
  padding-top: 12px;
}

.analytics-profile-detail .media-container .type-icon-div{
  position : absolute;
  top: 5%;
  right: 5%;
}

.home-view {
  min-height: 95vh;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.home-view .action-button{

}




.explore-view {
  min-height: 95vh;
}

.new-publish-view {
  height: 1000px;
}

.profile {
  min-height: 80vh;
}

.profile .profile-header-container {
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid #dce4ec;
}

.profile .kpi-container{

}

.profile .kpi-container {
  border-top: 1px solid #dce4ec;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #dce4ec;
}

.profile .kpi-container .element{
  text-align: center;
}

.profile .select-container {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #dce4ec;
}

.profile .select-container .select-icon {
  height: 30px;
  display: block;
  margin: auto;
  margin-top: 5px;
}

.profile .select-container .select-icon-ii {
  height: 24px;
  display: block;
  margin: auto;
  margin-top: 7px;
  fill: var(--purplepink);
}

.profile .feed-container {
  border-bottom: 1px solid #dce4ec;
  padding-bottom: 12px;
  padding-top: 12px;
}

.profile .type-icon-div {
  position : absolute;
  top: 5%;
  right: 5%;
}

.profile .engagement-icon-div {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 30%;
  text-align: center;
  color:white;
}

.profile .hashtag-container {
  padding-left: 16px;
  padding-right: 16px;
}

.profile .hashtag-element-container {
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid #dce4ec;
}

.profile .hashtag-metadata-container {
  text-align: center;
  padding-bottom: 10px;
}

.profile .hashtag-title-container {
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 600;
  color: #00376b;
  fontsize: 12px;
}

/* ---------------------------------------------------
    ONBOARDING
----------------------------------------------------- */

.nav-onboarding {
    height: 40px;
    background-color: var(--purplepink);
    position: relative;
    top: 0;
    z-index: 1;
}

.nav-onboarding-logo{
    margin-top: -5px;
    width: 150px;
}

.onboarding-nav {
    height: 45px;
    position: relative;
    top: 0;
    z-index: 400;
    border-bottom: 1px solid #dce4ec;
    padding-bottom: 20px;
}


.onboarding-nav .logo-div{
  border-right: 1px solid #dce4ec;
  margin-left: auto;
  margin-right: 10px;
}

.onboarding-nav .outer {
}

.onboarding-nav .inner {
}

.onboarding-nav .logo-img {
  height: 40px;
}

.onboarding-nav .trial-text-div {
}

.onboarding-nav .left-container {
}

.onboarding-nav .right-container {
}

.onboarding-nav .logout-link {
}

.header-plans .button-back {
  box-shadow: inset 0 0 0 1px #dce4ec;
  width: 65px;
  height: 30px;
}

.connect-social .container-onboarding {
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connect-social .seperator {
  margin-top: 50px;
  padding-top: 20px;
  border-top: 1px solid #dce4ec;
}

.connect-social .grid-icon {
  display: inline
}

.connect-social .list-inline-item{
  display: inline-block;
}

.connect-social .icon-list {
  margin: 0;
  padding: 5px;
  padding-top: 15px;
  list-style-type: none;
  text-align: center;
}

.connect-social .icon-list ul li {
  display: inline;
}

@media screen and (min-width: 768px) {
  .connect-social .card-container {
    width: 450px;
  }
}

.connect-social .card-container {
  position: relative;
}

.connect-social .thumb-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.connect-social .thumb-div {
  height:  90px;
  width: 90px;
  border-radius: 50%;
  border: 1px solid #dce4ec;
}

.connect-social .test {
  position: relative;
}

.connect-social .thumb-icon {
  display: block;
  height: auto;
  width: 100%;
}

.plans-overview .pricing-top-seller-container {
  margin-top: -24px;
  margin-left: -16px;
  height: 30px;
  width: 100%;
  color: var(--white);
  background-color:  var(--purplepink);
  z-index: 20;
  position: absolute;
}

.plans-overview .pricing-container-item {
  border: 1px solid #dce4ec;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1;
  position: relative;
  border: 1px solid #dce4ec;
  padding: 1.5rem 1rem;
  margin: 0 .5rem;
  border-radius: 3px;
}

.plans-overview .description-text {
  font-size: 12px;
}

.plans-overview .currency {
  font-size: 1.5rem;
  top: -1.5rem;
}

.plans-overview .your_plan {
  box-shadow: inset 0 0 0 1px #dce4ec;
  width: 100%;
  height: 42px;
}

.plans-overview .start_trial {
  box-shadow: inset 0 0 0 1px #dce4ec;
  width: 100%;
  height: 42px;
  background-color: var(--mango);
}

.plans-overview .downgrade {
  box-shadow: inset 0 0 0 1px #dce4ec;
  width: 100%;
  height: 42px;
  background-color: var(--midgrey);
}

.plans-overview .header-div {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 20px;
}

.plans-overview .price-value {
  font-size: 4rem;
  font-weight: 600;
  line-height: 4rem;
  display: inline-block;
  letter-spacing: -2px;
}

.plans-overview .check-row12__________________________ {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1;
}

.plans-overview .check-row {
  margin: 0;
  padding: 5px;
  padding-top: 15px;
  list-style-type: none;
}

.plans-overview .icon-list ul li {
  display: inline;
}

.plans-overview .icon {
  display: inline;
  cursor: pointer;
}

.plans-overview .list-inline-item {
  display: inline-block;
}


.plans-overview .check-row-item {
}

.plans-overview .description-div {
  height: 80px;
}

.feature-table-view .feature-table {
    max-width: 1010px;
    width:100%;
    height: 5rem;
    display: table;
    table-layout:fixed;
}
.feature-table-view .feature-table  ul {
  margin:0;
  display: flex;
  flex-direction: row;
}
.feature-table-view .feature-table li {
  flex-grow:1;
  flex-basis: 0;
  list-style:none;
  text-align:center;
  font-size:12px;
  height: inherit;
  left:auto;
  vertical-align:top;
  text-align:left;
  padding-top:5px;
  cursor:pointer;
}

.feature-table-view .icon {
  display: inline;
  cursor: pointer;
}

.feature-table-view .list-inline-item {
  display: inline-block;
}

.feature-table-view .key-feature-table {
  background-color: #fff4d4
}
.feature-table-view .all-feature-table {
  background-color: var(--lightgrey);
}

/* ---------------------------------------------------
    ONBOARDING
----------------------------------------------------- */

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    overflow-x: hidden!important;
    overflow-y: hidden;
    min-height: 100vh;
    align-items: initial;
}
#content:after{
    display: block;
    min-height: 100px;
}
#sidebar {
    /* min-width: 300px;
    max-width: 300px; */
    background-image: linear-gradient(to bottom,#d21464, #b4004c);
    padding: 20px;
    transition: all 0.3s;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
    max-height: 100%;
    height: 1123px;
    font-family: Montserrat;
}

#sidebar .sidebar-icon {
    margin-right: 10px;
}

#sidebar .sidebar-row {
    text-align: left;
    font-size: 16px;
    display: flex;
    width: 100%;
}

#sidebar .sidebar-row:hover {
    color: var(--mango);
}


#sidebar .sidebar-row.active {
    color: var(--mango);;
}

#sidebar .active .sidebar-desc {
    color: var(--mango);;
    /* text-decoration: underline var(--mango);; */
    border-bottom: 1px solid var(--mango);
    padding-bottom: 8px
}

#sidebar .sidebar-row{
       padding-top: 16px;
}

#sidebar .sidebar-row-disabled{
       padding-top: 16px;
       color: var(--anthracite);;
}


/* .iconsangle_double_left {
  width: 32px;
  height: 32px;
  object-fit: contain;
} */
/* #sidebar.active {
    min-width: 100px;
    max-width: 100px;
    text-align: center;
    padding: 13px;
} */

/* #sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
    display: none;
} */

/* #sidebar.active .sidebar-header strong {
    display: block;
} */



/* #sidebar.active ul li a {
    padding: 20px 0px;
    text-align: center;
    font-size: 0.85em;
} */

/* #sidebar.active .sidebar-icon {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
} */



/* #sidebar.active .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
} */

/* #sidebar.active .accordion-toggle-selected::after{
    top: 83px !important;
    right: 59% !important;
    font-size: 17px !important;
}
#sidebar.active #selectedAccount{
    height: 110px;
} */




/* #sidebar.active ul li a span.sidebar-text{
        display: none !important;
    } */

/* .dot-red-active {
    height: 12px;
    width: 12px;
    background-color: var(--mango);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: -2px;
}

a[data-toggle="collapse"] {
    position: relative;
}


 .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 33%;
    right: 42px;
    bottom: 6px;
    border:none;
}

 .account-component{
   width: 100%;
    height: 84px;
    margin-left: 0px;
    border-radius: 20px;
    box-shadow: 8px 6px 45px -4px rgba(0, 0, 0, 0.09);
    background-color: var(--white);
    margin-bottom:10px;
}

 .account-component-selected{
    width: 100%;
    height: 84px;
    margin-left: 0px;
    border-radius: 20px;
    box-shadow: 8px 6px 45px -4px rgba(0, 0, 0, 0.09);
    background-color: var(--white);
    margin-bottom:10px;
    display: inline-flex !important;
}
 span.account-name {

  font-family: Montserrat;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
}

#sidebar.active ul li div.profile-text-container{
    display: none;
}

#sidebar.active ul li div.profile-text-container-selected{
    display: none;
}

#sidebar.active ul li a.account-component-selected{
    display: block !important;
}

#sidebar.active ul li a div.profile-img-container-selected{
    margin-top: 0px;
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--lightgrey);
}

/* p.help-videos-sub-heading{
    color: var(--midgrey);
    font-size: 20px;
}

a.help-videos-sub-heading-link{
    color: var(--midgrey);
    font-size: 16px;
    text-decoration: underline;
}

.help-videos-menu-container{
     box-shadow: 2px 2px 16px 0 rgba(0, 0, 0, 0.09);
  background-color: var(--white);
    max-height: 150px;
    width: 100%;
    padding: 20px;
    text-align: center;
}

.help-videos-links{
    color: var(--purplepink);
    font-size: 30px;
    font-weight: 700;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;

}
.help-videos-links:hover{
    color: var(--mango);
}
.help-videos-numbers{
    font-size: 60px !important;
    padding-right: 20px;
    border-right: 1px solid var(--lightgrey);
}
.help-videos-text{
    padding-left: 20px;
} */


/* span.account-title {

  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--anthracite);
    margin-bottom: 3px;
}
span.inactive-account{
    color:var(--midgrey);
    font-size: 15px;
      font-weight: bold;

}
img.account-component-image-active{
    width:46px;
    height: 46px;
    border-radius: 50px;
}
img.account-component-image-menu{
    width: 32px;
    border: 1px solid var(--white);
    border-radius: 20px;
}
img.account-component-image-inactive{
    width:46px;
    border-radius: 50px;
    opacity: 0.7;
}
.profile-img-container{
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    padding-right: 0px;
}
.profile-img-container-selected{
    margin-top: 9px;
    margin-right: 0px;
    padding-left: 10px;
    padding-top: 10px;
    display: inline-table;
    padding-right:0px;
}
input.checkbox-uncheked{
    width: 20px !important;
  height: 20px;
  border-radius: 5px;
  border: solid 1px #c4c4c4;
}

.profile-text-container{
    overflow: hidden;
    font-size: 17px;
}

.multiselect-text{
    color: var(--purplepink);
}
.multiselect__option--selected.multiselect__option--highlight {
    background: var(--purplepink) !important;
    color: #fff;
}

.multiselect__option--highlight {
    background: var(--mango) !important;
    outline: none;
    color: #fff;
}

.profile-text-container-selected{
    padding-left: 0px;
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
    border-right: solid 1px var(--lightgrey);
}
.dot {
    height: 12px;
    width: 12px;
    background-color: var(--okgreen);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
}
.dot-active{
    height: 12px;
    width: 12px;
    background-color: var(--okgreen);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 2px;
}
.dot-red {
    height: 12px;
    width: 12px;
    background-color: var(--mango);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
}

.pink-menu-href{
    padding:10px;
}
.account-component-image-inactive{
  width: 46px;
  height: 46px;
  box-shadow: 8px 6px 45px -4px rgba(0, 0, 0, 0.09);
  border: solid 1px var(--white);
  background-blend-mode: lighten, color;
  background-image: linear-gradient(to bottom, rgba(240, 240, 240, 0.5), rgba(240, 240, 240, 0.5)), linear-gradient(to bottom, var(--lightgrey), var(--lightgrey));

}

 .tabs__link{
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--midgrey);
}

.active_tab{
    font-weight: bold;
    color: var(--purplepink) !important;
    text-decoration: none;
    text-transform: none;
}

.statistics-nav-dropdown:after{
    font-family: 'Font Awesome\ 5 Free';
    content: "\f106";
    color: var(--midgrey);
}

.accordion-toggle-selected:after {
    font-family: 'Font Awesome\ 5 Free';
    content: "\f106";
    color: var(--midgrey);
    right: 18px ;
    top: 39%;
}
.accordion-toggle.collapsed:after {
    font-family: 'Font Awesome\ 5 Free';
  content:"\f107";
}
.accordion-toggle-selected.collapsed:after {
    font-family: 'Font Awesome\ 5 Free';
    content: "\f107";
    color: var(--midgrey);
    right: 16px ;
    top: 39%;
}
.iconsclose {
    width: 32px;
    height: 32px;
    float: right;
    margin-top: -10px;
}  */

/* .add-new-account-btn{
    background: var(--purplepink);
    border-radius: 32px;
    padding: 4px;
    width: 46px;
} */

/* ---------------------------------------------------
    Header
----------------------------------------------------- */

.header-view .divider {
  border-bottom:  solid 1px var(--lightgrey);
}

/* ---------------------------------------------------
    Header
----------------------------------------------------- */

/* ---------------------------------------------------
    Hashtag Generator
----------------------------------------------------- */

.generator-view .checkbox {
  border: 1px solid var(--mango);
}

/* ---------------------------------------------------
    Hashtag Generator
----------------------------------------------------- */

/* ---------------------------------------------------
    SETTINGS STYLE
----------------------------------------------------- */

.setting-view .social-icon {
  margin-left: 10px;
  color: var(--white);
  display: inline;
}

.setting-view .social-container {
  padding-bottom: 5px;
  height: 56px;
}

.setting-view .social-font {
  display: inline;
}

.settings-card .follower-result{
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--midgrey);
}
.settings-card .follower-label{
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
}

.settings-card .big-profile-img-container{
  margin-top: 21%;
}

.settings-card .big-profile-img {
  width: 70px;
  height: 70px;
}

.settings-card .big-account-title {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--anthracite);
  margin-bottom: 5px;
}

.settings-card .big-account-name {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
}

.settings-card .big-account-plan {
  margin-top: 34px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--purplepink);
  text-transform:capitalize;
}

.settings-card .div-line {
  border-right: solid 1px var(--lightgrey);
}

.settings-card .dashboard-label-statistics{
    font-size: 16px;
    font-weight: 600;
    color: gray;
    margin: 5px;
}



.settings-card .content-parent{

     display:inline-block;

}

.settings-card .account-statistic-icons{
    width: 30px;
}

.settings-card .dashboard-cont-statistics{
    margin-bottom: 3%;
    padding-bottom: 10px;
    border-bottom: solid var(--lightgrey) 1px;
}


.new-account-button span{
  width: 159px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
    margin-top: 13px;
}

.new-account-button .button-container {
    width: 60px;
    height: 60px;
    margin-left: 13px;
}

.new-account-button .backgound-color-class {
  background-color: var(--purplepink);
  box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}

/* ---------------------------------------------------
    SETTINGS STYLE
----------------------------------------------------- */

.setting-view .proceed-button {
  width: 200px;
  height: 70px;
  background-color: var(--purplepink);
  color: var(--white);
  font-size: 15px;
}

.setting-view .proceed-button:hover {
  background-color: var(--mango);
}


/* ---------------------------------------------------
    SETTINGS STYLE
----------------------------------------------------- */

/* ---------------------------------------------------
    ANALYTICS STYLE
----------------------------------------------------- */

.analytics-view .publish-button .button-container {
    width: 60px;
    height: 60px;
    margin-left: 13px;
}

.analytics-view .publish-button .backgound-color-class {
  background-color: var(--purplepink);
  box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}

.analytics-view .publish-button span{
  width: 159px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
    margin-top: 13px;
}

.analytics-view .publish-button .button-container {
    width: 60px;
    height: 60px;
    margin-left: 13px;
}

.analytics-view .publish-button .backgound-color-class {
  background-color: var(--purplepink);
  box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}


/* ---------------------------------------------------
    DASHBOARD STYLE
----------------------------------------------------- */

.dashboard-view .publish-button .button-container {
    width: 60px;
    height: 60px;
    margin-left: 13px;
}

.dashboard-view .publish-button .backgound-color-class {
  background-color: var(--purplepink);
  box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}

.dashboard-view .publish-button span{
  width: 159px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
    margin-top: 13px;
}

.dashboard-view .publish-button .button-container {
    width: 60px;
    height: 60px;
    margin-left: 13px;
}

.dashboard-view .publish-button .backgound-color-class {
  background-color: var(--purplepink);
  box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}

.dashboard-view .dashboard-label-start{
    font-size: 18px;
    font-weight: 600;
    margin: 5px;
    color: var(--mango);
}

.big-dot{
    margin-left: -20px;
    width: 20px;
    height: 20px;
    background-color: var(--okgreen);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-top: 5%;
    top:-55px;
    right:-65px;
    z-index: 1;
}
/* --------------------------------------------------
 FOOTER STYLE
----------------------------------------------------- */
.main-footer1 {
    clear: both;
    background: var(--white);
    padding: 20px;
    width: 100%;
    bottom: 0;
    position: relative;
}

.main-footer .footer-font > a {
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--anthracite);
}

.main-footer .footer-font span {
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--anthracite);
}

/* ---------------------------------------------------
     PUBLISH VIEW
----------------------------------------------------- */

.publish-view .comment-textarea {
  width: 80%;
  min-height: 100px;
  max-height:35vh;
}

.publish-view .input-textarea-div {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    resize: none;
}

.input-textarea {
  width: 100%;
  resize: none;
  border-width: 1;
  border:solid 1px;
  border-spacing: 15px;
  border-color: #d5d6d7;
  border-radius: 10px;
  padding: 5px;
  font-size: 15px;
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
  font-weight: 600;
}


.publish-view .input-textarea::-webkit-scrollbar {
    display: none;
}


.publish-view .caption-textarea {
  padding-top: 15px;
  padding-right: 5px;
  padding-bottom: 15px;
  min-height: 100px;
  max-height:35vh;
  /* max-height: 220px; */
  /* overflow: scroll; */
}


.caption-textarea .emoji-button  {
  width: 20%;
  padding-right: 5px;
  padding-left: 5px;
}

.publish-view .change-time-button {
  text-decoration: underline;
  cursor: pointer;
  color: var(--purplepink);
}


.publish-view .calendar-edit-button {
  cursor: pointer;
  text-decoration: underline;
  padding-right: 5px;
}

.publish-view .scroll-view {
  min-height: 5000px;
  overflow: scroll;
  overflow: hidden;
}

.publish-view .calendar-placeholder-icon{
     width: 50px;
     border-radius: 10px;
}

.publish-view .publish-button {
    padding-bottom: 20px;
}

.publish-view .publish-button .button-container {
    width: 60px;
    height: 60px;
    margin-left: 13px;
}

.publish-view .publish-button .backgound-color-class {
  background-color: var(--purplepink);
  box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}

.publish-view .publish-button span{
  width: 159px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
    margin-top: 13px;
}

.publish-view .publish-overview {
  weight: 290px;
  height: 150px;
  font-size: 8px;
  font-family: Montserrat;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
  font-weight: 600;
}

.publish-view .publish-overview .calendar-empty{
  background-color: var(--lightgrey);
  color: var(--midgrey);
}

.publish-view .publish-overview .calendar-free{
}

.publish-view .publish-overview .calendar-event{
  background-color: var(--mango);
}

.publish-view .text-input {
  width: 250px;
  margin-bottom: 5px;
}

.publish-view .icon-list {
  margin: 0;
  padding: 5px;
  padding-top: 15px;
  list-style-type: none;
  text-align: center;
}

.publish-view .icon-list ul li {
  display: inline;
}

.publish-view .icon {
  display: inline;
  cursor: pointer;
}

.publish-view .icon-not-available {
  color: var(--lightgrey1)
}

.publish-view .icon-not-selected {
  color: var(--midgrey)
}

.publish-view .icon-selected {
  color: var(--purplepink)
}

.publish-view .feature-div {
  width: 300px;
  height: 36px;
}

.publish-view .content-container {
  margin: auto;
}

.publish-view .feature-icon {
  display: inline;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.publish-view .feature-description {
  display: inline;
  padding-right: 45px;
}

.publish-view .feature-description-text {
  font-family: Montserrat;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 11px;
  font-weight: 400;
  color: var(--lightgrey1);
}

.publish-view .container-feature {
  height: 36px;
  padding: 5px 0;
}

.publish-view .container-icon {
  padding-left: 10px;
}

.publish-view .container-feature {
}

.publish-view .container-upgrade {
  text-align: right;
  padding-right: 5px;
}

.publish-view .feature-upgrade {
  color: var(--purplepink);
  cursor:pointer;
}

.hp-network li:not(:first-child) {
  padding-left: 1rem!important;
}
/* ---------------------------------------------------
    Inspire View
----------------------------------------------------- */
.inspire-view .calendar-card {
}

/* ---------------------------------------------------
    Will Promo

----------------------------------------------------- */

.insta-zoom-view .big-input {
  height: 52px;
  color: white;
  background-color: var(--midgrey);
  text-align: center;
  font-size: 33px;
}

.insta-zoom-view .big-button {
  height: 52px;
  width: 100%;
  font-size: 16px;
}

.insta-zoom-view .big-input:focus::placeholder {
  color: transparent;
}


 .insta-zoom-view .big-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
  font-size: 33px;
}


/* ---------------------------------------------------
    Will Promo
----------------------------------------------------- */

/* ---------------------------------------------------
    Hashtag Generator
----------------------------------------------------- */

.generator-view .account-input-box {
  border:solid  0.2rem var(--mango);
  border-style: dashed;
  padding:10px;
  text-align: center;
}

.generator-view .hashtag-box {
  /* border:solid  0.2rem var(--mango); */
  padding:10px;
}

.generator-view .account-input {
  height: 46px;
  color: white;
  background-color: var(--midgrey);
  text-align: center;
  font-size: 20px;
}

 .generator-view .account-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
  font-size: 20px;
}

.generator-view .account-input:focus::placeholder {
  color: transparent;
}

.generator-view .account-input-button {
  height: 46px;
  width: 100%;
  font-size: 20px;
  background-color: var(--purplepink);
}

.generator-view .hashtag-input {
  height: 52px;
  /* color: white; */
  /* color: black; */
  color: var(--midgrey);
  background-color: white;
  /* background-color: var(--midgrey); */
  /* text-align: center; */
  font-size: 33px;
}

.generator-view .do-search-button {
  height: 52px;
  width: 100%;
}

.generator-view .hashtag-input:focus::placeholder {
  /* color: transparent; */
  color: var(--midgrey);
}


 .generator-view .hashtag-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--midgrey);
  /* color: white; */
  opacity: 1; /* Firefox */
  font-size: 33px;
  border-color: var(--midgrey);
}

.generator-view .hashtag-input-info-text {
  /* background-color: var(--mango); */
  color : var(--mango);
}

.generator-view .buffer{
  height: 300px;
}

.hashtag_manager_test1 {
  height: 350px;
}

.generator-view .mobile-banner{
  background: var(--midgrey);
  overflow: hidden;
}

.generator-view .mobile-banner-text {
  color: #fff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  padding: 5px;
}

.generator-view .mobile-banner-text .mobile-banner-subtitle strong {
  font-size: 1.0rem;
}

.hide-hashtags__overlay {
  position: absolute;
  /* background: var(--midgrey); */
  background: var(--midgrey);
  top: 242px;
  width: 100%;
  overflow: hidden;
}

.hide-hashtags__overlay1 {
  background: var(--midgrey);
  top: 415px;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
}

.hide-hashtags__image {
  background-image: url(../../img/duke_jump.png);
  /* height: 129px; */
  height: 168px;
  /* width: 175px; */
  width: 227px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  margin: 0 auto 10px;
}

.hide-hashtags__text {
  color: #fff;
  white-space: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  padding: 30px;
}

.hide-hashtags__text .hide-hashtags__subtitle strong {
  font-size: 1.35rem;
}

.hide-hashtags__subtitle_number {
  font-size: 30px;
  font-weight: 700;
}

.hide-hashtags__title {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
}



.hide-hashtags__subtitle p {
  margin-bottom: 0;
}

.hide-hashtags__btnblock {
  margin-top: 35px;
}

.hide-hashtags__btnblock a.btn {
  height: 43px;
  border-radius: 6px;
  font-size: 23px;
  font-weight: 700;
  text-align: left;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 0 auto;
  background: #fff;
  color: #4249d4;
  padding: 35px 75px;
}

.hide-hashtags__btnblock a.btn .btn-icon {
  font-size: 25px;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-left: 10px;
}

.item-hash-blur .item-hash,
.item-hash-blur .item-info {
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

/* ---------------------------------------------------
  Hashtag GEnerator
----------------------------------------------------- */

/* ---------------------------------------------------
    Digistore Formular

----------------------------------------------------- */

.purchase-view .digistore-formular {
  height: 1600px;
}

/* ---------------------------------------------------
    Digistore Formular

----------------------------------------------------- */
.promo-view {
}

.promo-view .promo-1 {
  background-color: var(--purplepink);
}
.promo-view .promo-2 {
  background-color: var(--mango);
}
.promo-view .slide {
  cursor: pointer;
}

.promo-view .promo-font {
  padding-top: 20px;
  padding-left: 10px;
  font-size: 60px;
  text-align: center;
}

.promo-view .slide-buy {
  height: 100%;
}

.promo-view .promo-font-1 {
  padding-top: 20px;
  padding-bottom: 150px;
}

.promo-view .slide-buy .headline-div {
  padding: 10px;

}
.promo-view .slide-buy .headline-div span {
  font-size: 60px;
  text-align: center;
}

.promo-view .slide-buy .text-div {
  padding: 20px;
  padding-left: 50px;
  text-align: left
}

.promo-view .slide-buy .text-div li {
  padding-bottom: 25px;
}

.upgrade-banner {
  margin-right: 20px;
  width: 250px;
  height: 100%;
  background-color: var(--purplepink);
  text-align: center;
  padding: 8px 0;
  border-radius: 20px;
  box-shadow: 8px 6px 45px -4px rgba(0, 0, 0, 0.09);
}

.upgrade-banner .text{
  color: var(--white);
}

.list-style ul{
  list-style-type: circle;
}

.promo-view .slide-buy .button-div {
  padding-top: 90%;
}

.promo-register-box  {
  color: white;
}

.promo-register-box .container {
  text-align: center;
  align-items: center;
  padding: 10px;
  background-color: var(--mango);
  height: 100px;
}

.promo-register-box-grow  {
  color: white;
}

.promo-register-box-grow .container {
  text-align: center;
  align-items: center;
  padding: 10px;
  background-color: var(--mango);
  height: 170px;
}

.promo-register-box-influ-list  {
  color: white;
}

.promo-register-box-influ-list .container {
  text-align: center;
  align-items: center;
  padding: 10px;
  background-color: var(--mango);
  height: 280px;
}


/* ---------------------------------------------------
    Promo View
----------------------------------------------------- */

/* ---------------------------------------------------
    Account Setting View
----------------------------------------------------- */

.account-setting-view .container-component{
  width: 60%;
  border-radius: 20px;
  box-shadow: 8px 6px 45px -4px rgba(0, 0, 0, 0.09);
  background-color: var(--white);
  margin-bottom: 25px;
}

.account-setting-view .member-badge {
    padding: 10px;
    margin-bottom: 10px;
    margin-right: 5px;
    border-radius: 4px;
    background-color: var(--mango);
    font-family: Montserrat;
    font-size:11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--anthracite);
}

.account-setting-view .konto-container {
    border-radius: 20px;
    box-shadow: 8px 6px 45px -4px rgba(0, 0, 0, 0.09);
    background-color: var(--white);
    height: 230px;
}

.account-setting-view .konto-mango-container {
    border-radius: 20px;
    box-shadow: 8px 6px 45px -4px rgba(0, 0, 0, 0.09);
    background-color: var(--mango);
    height: 230px;
}

.account-setting-view .konto-header{
  font-family: Montserrat;
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}

.account-setting-view .konto-labels{
    font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--anthracite);
}

.account-setting-view .konto-bold-text{
    font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--anthracite);

}

.account-setting-view .konto-black-btn{
    height: 35px;
  border-radius: 22.5px;
  background-color: var(--anthracite);
    color: var(--white);
    font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}


.account-setting-view .setting-row {
  width: 80%
}

.account-setting-view .einstellungen-component{
  border-radius: 20px;
  box-shadow: 8px 6px 45px -4px rgba(0, 0, 0, 0.09);
  background-color: var(--white);
  padding:10px;
  padding-bottom: 50px;
  margin-bottom: 20px;
}

.account-setting-view .kontostellungen-divider{
    border-right: solid 1px var(--lightgrey);
}

.account-setting-view .kontoeinstellungen-account-text{
  font-family: Montserrat;
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--midgrey);
}

.account-setting-view .kontoeinstellungen-account-text-2{
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--midgrey);
}

.account-setting-view .paket{
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--purplepink);
}

.account-setting-view  .kontoeinstellungen-upgrade-btn {
  border-radius: 22.5px;
  background-color: var(--mango);
  color: var(--anthracite);
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  height: 35px;
}

.account-setting-view .kontoeinstellungen-outlined-btn{
  border-radius: 22.5px;
  background-color: var(--white);
  color: var(--anthracite);
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border:2px solid var(--anthracite);
  height: 35px;
}

.account-setting-view .konto-form-input{
    border-radius: 10px;
    border: solid 1px #c4c4c4;
}

.account-setting-view .konto-form-label{
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--anthracite);
}

.account-setting-view .collapsible-header{
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--purplepink)
}

.account-setting-view .collapsible-element{
    border-radius: 30px;
}

.account-setting-view .user-id-input{
    background-color: var(--midgrey);
}

.account-setting-view .price-tabs{
    height: 395px;
}

.account-setting-view #kontoprice.price-tabs>.active{
    height: 100%;
    padding: 0px;
    border-radius: 10px;
}

.account-setting-view .price{
    font-family: Montserrat;
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--purplepink);

}


.account-setting-view .price-package-content{
  border-radius: 10px;
  border: solid 1px var(--midgrey);
  height: 394px;
}

.account-setting-view .price-package-content .active{
   border: solid 2px var(--purplepink);
   border-radius: 10px;
}

.account-setting-view .price-package-content.active{
   border: solid 2px var(--purplepink);
   border-radius: 10px;
}
.account-setting-view .price-package-content:hover{
    border: solid 2px var(--purplepink);
    border-radius: 10px;
}

.account-setting-view .collapsible-header{
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--purplepink)
}


.account-setting-view .price-currency{
    top: 10px;
    /* position: absolute; */
    font-family: Montserrat;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--purplepink);
}

.account-setting-view .paket-buchen-cont{
    margin-bottom: 20px;
}

.account-setting-view .kontoeinstellungen-upgrade-btn {
    border-radius: 22.5px;
    background-color: var(--mango);
    color: var(--anthracite);
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    height: 35px;

}

.account-setting-view .price-text{
    font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--anthracite);
}

.account-setting-view .platform-container{
  height: 90px;
}

.account-setting-view .feature-icon-container{
  display: inline;
  margin-right: 10px;
}

.account-setting-view .feature-icon{
  display: inline;
}

.account-setting-view .feature-text{
  display: inline;
  padding-left: 0px;
}



/* ---------------------------------------------------
    Account Setting View
----------------------------------------------------- */

/* ---------------------------------------------------
    Global STYLE
----------------------------------------------------- */

.icon {
  display: inline;
  cursor: pointer;
}


.container-right{
  padding-right: 10px;
}

.du-page-title{
    padding-top: 20px;
    padding-bottom: 40px;
    font-family: Montserrat, serif;
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--purplepink);
}

.color-grey {
  color: var(--midgrey);
}



.du-font {
  font-family: Montserrat;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
  font-weight: 600;
}

.du-font-bold {
  font-weight: bold;
}

.du-font-1 {
  font-size: 11px;
}

.du-font-2 {
  font-size: 13px;
}

.du-font-3 {
  font-size: 15px;
}

.du-font-4 {
  font-size: 19px;
}

.du-font-5 {
  font-size: 25px;
}

.du-font-midgrey {
  color: var(--midgrey);
}

.du-font-lightgrey1 {
  color: var(--midgrey);
}

.du-font-dark {
  color: var(--anthracite);
}

.du-font-pink {
  color: var(--purplepink);
}

.du-font-mango {
  color: var(--mango);
}

.du-font-white {
  color: white;
}

.du-button {
  background-color: var(--mango);
  color: var(--anthracite);
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.du-button-pink {
  background-color: var(--purplepink);
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.du-button-outline {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.du-button-grey {
  background-color: var(--midgrey);
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.du-button-black {
  background-color: var(--anthracite);
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.du-button-mango {
  background-color: var(--mango);
  color: white;
}

.du-button-white {
  background-color: var(--white);
  color: var(--midgrey);
  border: 1px solid #dce4ec;
}

.du-button:hover {
  background-color: #fabe8c;
}

.du-button-pink:hover {
  background-color: var(--darkgrey);
}

.du-card{
  border-radius: 20px;
    box-shadow: 8px 6px 45px -4px rgba(0, 0, 0, 0.09);
    background-color: var(--white);
    color: var(--midgrey);
    margin-bottom: 30px;
    padding: 20px 18px;
    overflow-x: hidden;
}

.du-card .card-name {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--midgrey);
    margin-left: auto;
    margin-right: auto;
}

.du-card .card-text {
  font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--anthracite);
    padding-right: 5px;
}

.du-card .card-date {
  width: 220px;
 height: 15px;
 font-family: Montserrat;
 font-size: 12px;
 font-style: normal;
 font-stretch: normal;
 line-height: normal;
 letter-spacing: normal;
 color: var(--midgrey);
}

.du-card .card-img{
     width: 80px;
     border-radius: 10px;
}

.main-view {
  min-height:90vh;
}

.profile-view {
  min-height:90vh;
}

.left-view {
  /* width: 615px; */
  width: 100px;
}

.right-view {
  width: 293px;
}

/* ---------------------------------------------------
    Cookie-Banner
-----------------------------------------------------
*/

.cookie-more {
  text-decoration: underline;
}

/* ---------------------------------------------------
    Cookie-Banner
-----------------------------------------------------
*/

/* ---------------------------------------------------
    CardCss
-----------------------------------------------------
*/

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
