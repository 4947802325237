.card-feed-main {
  margin-bottom: 60px;
}
.card-feed-0-5 {
  height: 190px;
  padding-bottom: 10px;
}
.card-feed-1 {
  height: 300px;
  padding-bottom: 10px;
}

.card-feed-2 {
  height: 600px;
  padding-bottom: 10px;
}

.card-feed-infinitive {
  padding-bottom: 10px;
}


.card-feed-title {
  padding-left: 16px;
  padding-top: 16px;
}
