/* .dashboard-card {
    border-radius: 20px;
    box-shadow: 8px 6px 45px -4px rgba(0, 0, 0, 0.09);
    background-color: var(--white);
    color: var(--midgrey);
    margin-bottom: 30px;
    padding: 20px 18px;
        overflow-x: hidden;
}

.dashboard-card{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 16px 0 rgba(0, 0, 0, 0.09);
    background-color: var(--white);
} */

.dashboard-card .follower-result{
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--midgrey);
}
.dashboard-card .follower-label{
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
}

.dashboard-card .big-profile-img-container{
  margin-top: 21%;
}

.dashboard-card .big-profile-img {
  width: 70px;
  height: 70px;
}

.dashboard-card .big-account-title {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--anthracite);
  margin-bottom: 5px;
}

.dashboard-card .big-account-name {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
}

.dashboard-card .big-account-plan {
  margin-top: 34px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--purplepink);
}

.dashboard-card .div-line {
  border-right: solid 1px var(--lightgrey);
}

.dashboard-card .dashboard-label-statistics{
    font-size: 16px;
    font-weight: 600;
    color: gray;
    margin: 5px;
}

.dashboard-card .content-parent{

     display:inline-block;

}

.dashboard-card .account-statistic-icons{
    width: 30px;
}

.dashboard-card .dashboard-cont-statistics{
    margin-bottom: 3%;
    padding-bottom: 10px;
    border-bottom: solid var(--lightgrey) 1px;
}
