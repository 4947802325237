/* adjust */

.publish-view-main {
  margin-bottom: 20px;
}

.publish-view-main-button {
  padding: 10px;
}

.emoji-picker {
  z-index: 4;
  bottom:20px;
  left: 300px;
}

/* publish view start */

.publishImage .drop-zone-big {
  display: table-cell;
  vertical-align: middle;
  width: 142px;
  height: 142px;
}

.publishImage .drop-zone-small {
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.publishImage .delete-image-button {
  position: absolute;
  right: 0px;
  top: 2px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
}

.publishImage .delete-image-button:hover {
  transform: scale(1.25); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.publishImage .uploadLabel1 {
  opacity: 0.0;
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right:0;
  width: 100%;
  height:100%;
}

.publishImage .uploadLabel {
  display: flex;
  width: 100%;
  height:100%;
  cursor: pointer;
}

.publishImage .uploadIcon {
  margin:auto;
  width: 25px;
  height: 25px;
}

.publishImage .drop-zone-div:hover .uploadIcon{
  fill: rgba(37, 99, 235, 1);
}

.publish-caption textarea {
  border: none;
  resize: none;
  border: none;
}

.publish-calendar-day textarea {
  border: none;
  resize: none;
  border: none;
}

.my-photo-editor {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 20;
}


/* publish view end */


/* has to be done */
/* .publishCaption .emoji_modal {
  position: absolute;
  z-index: 1000;
  background-color: blue
} */
